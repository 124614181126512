export const PROJECT_MANAGEMENT_TITLE = 'Project Management';

export const PROJECT_SETUP_LINK = {
  title: 'Project Setup',
  to: '/create-new-project',
};

export const PROJECT_MANAGEMENT_LISTVIEW = {
  EN: {
    TITLE: 'Forms',
    SUBTITLE: 'Click Heading to sort and filter',
  },
};

export const CLA_FORM_CARD_NEW = {
  errorMessage: [
    'Enter at least one character',
    'Form name already exists',
    'Select at least one unit',
    'Form name limit exceeded',
  ],
  inputExtensionPlaceHolder: 'this extension is editable',
  inputExtensionValue: 'Copy ',
};

export const ALL_FORMS_LIBRARY = {
  title: 'All Form Library',
  formsNotInProjSubTitle: 'Forms NOT in the project',
};

export const CONTEXT_API_METHOD = {
  ADD_TO_CATEGORY: 'ADD_TO_CATEGORY',
  REMOVE_FROM_CATEGORY: 'REMOVE_FROM_CATEGORY',
  ADD_UNIT_LIST: 'ADD_UNIT_LIST',
  REMOVE_UNIT_LIST: 'REMOVE_UNIT_LIST',
  ADD_TO_LIST_VIEW: 'ADD_TO_LIST_VIEW',
  ADD_NEW_FORM_DETAILS: '    ADD_NEW_FORM_DETAILS',
  SET_UNIT_OPTIONS_LIST_VIEW: 'SET_UNIT_OPTIONS_LIST_VIEW',
  UPDATE_UNIT_SELECTED_LIST_VIEW: 'UPDATE_UNIT_SELECTED_LIST_VIEW',
  RESET_FORM_LIST_VIEW: 'RESET_FORM_LIST_VIEW',
  SET_PROJECT_FORM_STATE: 'SET_PROJECT_FORM_STATE',
  MODE: {
    ADD: 'add',
    DUPLICATE: 'duplicate',
  },
  OPERATON_TYPE: {
    ADD: 'add',
    REMOVE: 'remove',
  },
  DATAGRID_CONTEXT: {
    ON_CLICK_AWAY_LIST_VIEW: 'ON_CLICK_AWAY_LIST_VIEW',
    ON_DUPLICATE_ACTION: 'ON_DUPLICATE_ACTION',
    UPDATE_UNIT_SELECTED: 'UPDATE_UNIT_SELECTED',
    SAVE_FORM_IN_LIST: 'SAVE_FORM_IN_LIST',
    ADD_FORM_TO_LIST: 'ADD_FORM_TO_LIST',
    TYPE: {
      ADD: 'add',
      REMOVE: 'remove',
    },
  },
};

export const PM_CARD_VIEW_OPTIONS = [
  { id: '1', name: 'As List' },
  { id: '2', name: 'As Cards' },
];

export const PM_SORT_ENUM = {
  ASC: 'asc',
  DESC: 'desc',
};

export const PM_CARD_SORT_OPTIONS = [
  { id: 'formName', name: 'Alphabetical (Reset)' },
];

export const FORM_TEXT_LIMIT = {
  FORM_NAME_EXTENSION_LIMIT: 256,
};

export const PROJECT_MANAGEMENT_CONTEXT_MENU = {
  EN: {
    PROJECT_SETUP: 'Project Setup',
    RISK_SUMMARY: 'Risk Summary',
    INHERENT_RISK_FACTOR_SUMMARY: 'Inherent Risk Factor Summary',
    DEFICIENCY_SUMMARY: 'Deficiency Summary',
    REPORT_OF_PROCEDURES: 'Report of Procedures',
    INGEST_FORM_ANSWERS: 'Ingest Form Answers',
    UPDATE_METHODOLOGY_VERSION: 'Update Methodology Version',
    GENERATE_PROJECT_PDF: 'Generate Project PDF',
    CLIENT_SHARED_FORM: 'Share Forms with Client',
  },
};

export const PROJECT_MANAGEMENT_CONTEXT_MENU_LIST_ITEM = [
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.PROJECT_SETUP,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.RISK_SUMMARY,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.INHERENT_RISK_FACTOR_SUMMARY,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.DEFICIENCY_SUMMARY,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.REPORT_OF_PROCEDURES,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.INGEST_FORM_ANSWERS,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.UPDATE_METHODOLOGY_VERSION,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.GENERATE_PROJECT_PDF,
  PROJECT_MANAGEMENT_CONTEXT_MENU.EN.CLIENT_SHARED_FORM,
];

export const CLIENT_SHARED_FORM_MODAL = {
  EN: {
    TITLE: 'Share Forms with Client',
    SHARE_FORM: 'Share Forms',
    CANCEL: 'Cancel',
    TABLE_HEADER: {
      FORM_NAME: 'Form Name',
      SHARED_ON: 'Shared On',
      DUE_DATE: 'Due Date',
      STATUS: 'Status',
    },
  },
};

export const GENERATE_PROJECT_PDF_MODAL = {
  EN: {
    TITLE: 'Generate Project PDF',
    DESCRIPTION:
      'The PDF is being created. This process may take a few minutes.',
  },
};

export const UPDATE_METHODOLOGY_VERSION_MODAL = {
  EN: {
    REQUIRED: 'Required',
    MODAL: {
      CONFIRM_MODAL: 'ConfirmModal',
      NOTIFICATION_MODAL: 'NotificationModal',
    },
    WARNING: 'Warning',
    ARE_YOU_SURE_YOU_WANT_TO_UPDATE:
      'Are you sure you want to update? Changes cannot be undone.',
    METHODOLOGY_VERSION_UPDATED: 'Methodology Version Updated!',
    PROJECT_HAS_BEEN_UPDATED_METHODOLOGY:
      'The project has been updated to methodology version <label>',
    NEW_METHODOLOGY_UPDATE: 'New Methodology Update',
    SELECT_METHODOLOGY_VERSION: 'Select methodology version for this project',
    METHODOLOGY_VERSION: 'Methodology Version',
    PROJECT_WILL_BE_UPDATED_TO_THE_SELECTED_METHODOLOGY_VERSION:
      'The project will be updated to the selected methodology version. Click Update to continue',
    ERROR_IN_UPDATE_METHODOLOGY_VERSION:
      'An error was encountered upon updating the methodology version. Please refresh the page and try again or contact the Service Desk.',
  },
};

export const UPDATE_METHODOLOGY_IN_PROGRESS_MODAL = {
  EN: {
    NEW_METHODOLOGY_UPDATE_IN_PROGRESS: 'New Methodology Update In Progress',
    METHODOLOGY_VERSION_UPDATE_IN_PROGRESS:
      'Methodology version update is in progress. This process may take a few minutes.',
  },
};

export const UPDATE_METHODOLOGY_MODAL_INVALID_AUDIT_AREA_SCOTABD = {
  EN: {
    WARNING:
      'Any audit areas and/or SCOTABDs that are invalid in the new methodology version based on the industries, performance standards, and frameworks in your project will be deleted along with associated items such as risks, common identified risks, inherent risk factors, key controls, deficiencies and custom audit procedures. Further, this action could result in changes to audit program steps and permanent deletion of documentation completed (e.g., walkthrough documentation).',
    INVALID_AUDIT_AREAS: 'Invalid Audit Areas',
    INVALID_SCOTABDS: 'Invalid SCOTABDs',
    ASSOCIATED_WITH: 'Associated with $',
    ARE_YOU_SURE_YOU_WANT_TO_UPDATE:
      'Are you sure you want to update? Changes cannot be undone.',
  },
};

export const INGEST_FORM_ANSWERS_MODAL = {
  EN: {
    REQUIRED: 'Required',
    MODAL: {
      CONFIRM_MODAL: 'ConfirmModal',
      NOTIFICATION_MODAL: 'NotificationModal',
    },
    NEW_INGEST_FORM_ANSWERS: 'Ingest Form Answers',
    INGEST_FORM_INSTRUCTIONS:
      'This ingestion process will ingest answers from the below forms. Click the link to the Alteryx Gallery application and paste in the Project ID and Unit ID into the corresponding text boxes.',
    INGEST_FORM_NOTE:
      'Note that all answers that are pulled from the ingested forms will overwrite current answers in the AIS Smart Forms. Please review the affected forms carefully!',
    UNITS: 'Units',
    OPEN_ALTERYX_GALLERY: 'Open Alteryx Gallery',
    COPY_PROJECT_ID: 'Copy Project ID',
    COPY_UNIT_ID: 'Copy Unit ID',
  },
};

export const ACTIVE_USER_MODAL = {
  EN: {
    METHODOLOGY_VERSION_CANNOT_UPDATED:
      'Methodology Version Cannot Be Updated!',
    USERS_IN_THE_FOLLOWING_FORMS:
      'Users are in the following forms. In order to update, they must exit the project.',
    FORM_NAME: 'Form Name',
    USER_EDITING: 'User Editing',
  },
};

export const COPY_ANSWER_MODAL = {
  EN: {
    SUCCESS_LABEL: 'Success!',
    SUCCESS_CLOSE: 'Close',
    SUCCESS_MESSAGE: 'Data has been copied successfully.',
    WARNING_LABEL: 'Warning',
    WARNING_CANCEL: 'Cancel',
    WARNING_CONFIRM: 'Yes',
    WARNING_MESSAGE:
      'All existing data in the destination form will be overwritten when the copy is complete. Would you like to proceed?',
    COPYANSWER_CANCEL: 'Cancel',
    COPYANSWER_CONFIRM: 'Import from Selected Project',
    COPY_ANSWER_LABEL: 'Import Answers from Another Project',
    DROPDOWN_CLIENT_LABEL: 'Client',
    DROPDOWN_PROJECT_LABEL: 'Project',
    DROPDOWN_UNIT_LABEL: 'Unit',
    DROPDOWN_PLACEHOLDER: 'Select',
  },
};
