import { Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent } from '@mui/material/';
import { extractTextOrUrlFromLink } from '@utilities/urlHelpers';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './CLARichTextEditor.css';

const Link = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value) {
    const node = super.create(value);
    value = this.sanitize(value);
    value = extractTextOrUrlFromLink(node.href);
    node.setAttribute('href', value);
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    node.setAttribute('style', 'color: #0d6efd;');
    return node;
  }
}

Quill.register(CustomLink);

const ExpandedTextFormatter = (props) => {
  const { open, onChange, onClose, initialState, modules, maxLength } = props;

  const quillRef = useRef(null);  
  const [richTextFormatterValue, setRichTextFormatterValue] = useState(initialState);

  const handleSave = useCallback(() => {
    onChange(richTextFormatterValue);
    onClose(false);
  }, [onChange, onClose, richTextFormatterValue]);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);
  
  const handleRichTextValueChange = useCallback((value) => {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    if (editor.getText().length < maxLength)
      setRichTextFormatterValue(value);
    else
      editor.deleteText(maxLength, editor.getText().length - maxLength);
  }, [maxLength]);

  useEffect(() => {
    setRichTextFormatterValue(initialState);
  }, [initialState]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '560px',
          height: '546px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <ReactQuill
          ref={quillRef}
          className="rte-quill"
          modules={modules}
          value={richTextFormatterValue}
          theme="snow"
          onChange={handleRichTextValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disableElevation onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CLARichTextEditor = (props) => {
  const { onChange, onBlur, value, placeholder, expandable, height, disabled, error, onFocus, maxLength } = props;

  const [quillvalue, setQuillValue] = useState(null);
  const [open, setOpen] = useState(false);
  const quillRef = useRef(null);  
  const defaultMaxLength = maxLength && typeof maxLength === 'number' ? maxLength : 4096;

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ]
  };

  const handleQuillChange = useCallback((value) => {
    setQuillValue(value);
    onChange?.(value);
  }, [onChange]);

  const handleBlur = (e) => {
    onBlur?.(e, quillvalue);
  };

  const handleOnFocus = () => { 
    onFocus?.();
  };
  
  const handleTextAreaChange = useCallback((value) => {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    if (editor.getText().length < defaultMaxLength) {
      setQuillValue(value);
      onChange?.(value);
    } else {
      editor.deleteText(defaultMaxLength, editor.getText().length - defaultMaxLength);
    }
  }, [defaultMaxLength, onChange]);

  useEffect(() => {
    setQuillValue(value);
  }, [value]);

  return (
    <>
      <div className="rte-container">
        <div className={error ? "rte-quill-container-error" : "rte-quill-container"}>
          <ReactQuill
            ref={quillRef}
            placeholder={placeholder}
            className="rte-quill"
            modules={modules}
            value={quillvalue}
            theme="snow"
            onChange={handleTextAreaChange}
            onFocus={handleOnFocus}
            onBlur={handleBlur}
            readOnly={disabled}
            {...(height && { style: { height: height } })}
          />
        </div>
        <div>
          {expandable && !disabled && (
            <Button
              onClick={handleClickOpen}
              variant="text"
              sx={{
                fontFamily: 'museo-sans',
                textTransform: 'none',
                color: '#76D2D3',
                fontSize: '16px',
                p: 0,
                ':hover': {
                  backgroundColor: 'inherit',
                },
              }}
            >
              Expand
            </Button>
          )}
        </div>
      </div>
      <ExpandedTextFormatter
        open={open}
        onChange={handleQuillChange}
        onClose={handleClose}
        initialState={quillvalue}
        modules={modules}
        maxLength={defaultMaxLength}
      />
    </>
  );
};

CLARichTextEditor.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool
};

CLARichTextEditor.defaultProps = {
  value: '',
  placeholder: '',
  expandable: true,
  disabled: false,
  error: false,
};

export default CLARichTextEditor;
