import { useState, useEffect, useContext } from 'react';
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { useParams } from 'react-router-dom';

import { FIELD_TYPES, RULES } from '@ais/constants';
import { colors } from '@ais/theme';
import { SchemaContext } from '@ais/providers';
import { CriteriaRow } from '.';
import formCategoriesService from '@services/forms/formCategories';
import formServices, { useGetRuleCriteriaType, useAuditAreasByMethodologyVersionId, useGetFrameworksByMethodologyVersionId } from '@services/forms/forms';
import { generateRuleCriteriaTypes } from "@components/Forms/helpers";
import { useGetIndustriesByMethodologyVersionId } from '@services/client';
import { useGetPerformanceStandards } from '@services/forms/performanceStandards';
import { useLoading } from '@hooks/index';
import {useGETServiceTypeConfigurationByMethodologyVersionId} from '@services/methodology';

const findSections = (properties) => {
  const sections = [];
  properties.forEach((section) => {
    sections.push({
      label: section.title,
      value: section.id,
    });
  });
  return sections;
};

const findApplicableLocalFields = (properties, excludeId) => {
  const applicableTypes = [FIELD_TYPES.DROPDOWN, FIELD_TYPES.RADIOGROUP, FIELD_TYPES.CHECKBOXGROUP];
  const fields = [];
  properties.forEach((section) => {
    const sectionId = section.id;
    section.fields.forEach((field) => {
      field.forEach((innerField) => {
        if (applicableTypes.includes(innerField.type))
          if (excludeId) {
            if (excludeId !== innerField.id)
              fields.push({
                id: innerField.id,
                sectionId,
                label: innerField.label,
                options: innerField.options,
              });
          } else
            fields.push({
              id: innerField.id,
              sectionId,
              label: innerField.label,
              options: innerField.options,
            });
      });
    });
  });
  return fields;
};

const generateNewCriteria = () => ({
  id: uuidv4(),
  criteriaType: '',
  questionId: '',
  isExternalQuestion: false,
  any: false,
  isEqual: RULES.EQUALITY.IS,
  value: [],
});

export const Criterias = (props) => {
  const { additionalData, trigger } = props;

  const { methodologyId } = useParams();

  const { schema } = useContext(SchemaContext);

  const [sections, setSections] = useState([]);
  const [fields, setFields] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState({});
  const [processedIndustries, setProcessedIndustries] = useState([]);
  const [auditAreas, setAuditAreas] = useState([]);
  const [filteredAuditAreas, setFilteredAuditAreas] = useState([]);
  const [selectedAuditAreas, setSelectedAuditAreas] = useState({});
  const [scotabds, setScotabds] = useState([]);
  const [selectedScotabds, setSelectedScotabds] = useState({});
  const [frameworks, setFrameworks] = useState([]);
  const [performanceStandards, setPerformanceStandards] = useState([]);
  const [criterias, setCriterias] = useState([]);
  const [formCategoryOptions, setFormCategoryOptions] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [ruleCriteriaTypes, setRulesCriteriaTypes] = useState([]);
  const { data: ruleCriteriaTypeResult } = useGetRuleCriteriaType();
  const setLoading = useLoading();
  const { getValues, setValue } = useFormContext();
  const addCriteria = () => {
    const newCriteria = generateNewCriteria();
    setCriterias((prevState) => {
      const copy = [...prevState];
      copy.push(newCriteria);
      return copy;
    });
  };

  const deleteCriteria = (id) => {
    if (criterias.length > 1) {
      const filteredSelectedIndustries = { ...selectedIndustries };
      delete filteredSelectedIndustries[id];
      const filteredSelectedAuditAreas = { ...selectedAuditAreas };
      delete filteredSelectedAuditAreas[id];
      const filtered = criterias.filter((criteria) => criteria.id !== id);
      setSelectedIndustries(filteredSelectedIndustries);
      setSelectedAuditAreas(filteredSelectedAuditAreas);
      setCriterias([...filtered]);
    } else {
      setSelectedIndustries({});
      setSelectedAuditAreas({});
      setCriterias([generateNewCriteria()]);
    }
  };
  useEffect(() => {
    setRulesCriteriaTypes(
      ruleCriteriaTypeResult?.status === 200 ?
        generateRuleCriteriaTypes(ruleCriteriaTypeResult?.data?.filter(criteria => criteria.IsEnabled)) :
        []
    );
  }, [ruleCriteriaTypeResult]);

  const { data: industriesData, isLoading: isIndustriesDataLoading } = useGetIndustriesByMethodologyVersionId(methodologyId, true, true)
  const { data: auditAreasData, isLoading: isAuditAreasDataLoading } = useAuditAreasByMethodologyVersionId(methodologyId, true)
  const { data: frameworkData, isLoading: isFrameworkDataLoading } = useGetFrameworksByMethodologyVersionId(methodologyId, true)
  const { data: performanceStandardData, isLoading: isPerformanceStandardDataLoading } = useGetPerformanceStandards(methodologyId, true)
  const { data: serviceTypeConfiguration, isLoading: isServiceTypeConfiguration } = useGETServiceTypeConfigurationByMethodologyVersionId(methodologyId);

  useEffect(() => {
    setLoading(isIndustriesDataLoading || isAuditAreasDataLoading || isFrameworkDataLoading || isPerformanceStandardDataLoading || isServiceTypeConfiguration)
  }, [isIndustriesDataLoading, isAuditAreasDataLoading, isFrameworkDataLoading, isPerformanceStandardDataLoading, isServiceTypeConfiguration])

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const { data } = await formCategoriesService.getFormCategories(methodologyId);
        setFormCategoryOptions(
          data?.filter((item) => item.IsEnabled).map((e) => ({
            label: e.FormCategoryName,
            value: e.FormCategoryId,
          }))
        );
      } catch (error) {
        toast.error(error.toString());
      }
    };
    fetchCategoryData();
  }, []);

  useEffect(() => {
    if (getValues('rules.criterias').length) setCriterias(getValues('rules.criterias'));
    else setCriterias([generateNewCriteria()]);
  }, []);

  useUpdateEffect(
    function processSelectedIndustries() {
      let processed = [];
      Object.values(selectedIndustries).forEach((value) => {
        processed = [...new Set([...processed, ...value])];
      });
      setProcessedIndustries(processed);
    },
    [selectedIndustries]
  );

  useEffect(
    function filterAuditAreas() {
      const filtered = auditAreasData?.filter((auditArea) => {
        const results = [];
        processedIndustries.forEach((pi) =>
          results.push(auditArea?.methodologyIndustries?.includes(pi))
        );
        return results.some((result) => result === true);
      });
      setFilteredAuditAreas(filtered);
    },
    [processedIndustries, auditAreasData]
  );

  useUpdateEffect(
    function removeUnrelatedAuditAreasFromCriterias() {
      if (dataFetched) {
        Object.keys(selectedAuditAreas).forEach((id) => {
          const value = selectedAuditAreas[id][0];
          if (value) {
            const results = [];
            filteredAuditAreas.forEach((faa) => results.push(faa.value === value));
            if (!results.some((result) => result === true)) {
              setSelectedAuditAreas((prevState) => {
                const copy = { ...prevState };
                delete copy[id];
                return copy;
              });
              setCriterias((prevState) => {
                const filtered = prevState.filter((criteria) => criteria.id !== id);
                if (!filtered.length) filtered.push(generateNewCriteria());
                return filtered;
              });
            }
          }
        });
      }
    },
    [dataFetched, selectedAuditAreas, filteredAuditAreas]
  );

  useUpdateEffect(
    function removeUnrelatedScotabdsFromCriterias() {
      if (dataFetched) {
        Object.keys(selectedScotabds).forEach((id) => {
          const value = selectedScotabds[id];
          if (value.length) {
            const results = [];
            scotabds.forEach((scotabd) => results.push(value.includes(scotabd.value)));
            if (!results.some((result) => result === true)) {
              setSelectedScotabds((prevState) => {
                const copy = { ...prevState };
                delete copy[id];
                return copy;
              });
              setCriterias((prevState) => {
                const filtered = prevState.filter((criteria) => criteria.id !== id);
                if (!filtered.length) filtered.push(generateNewCriteria());
                return filtered;
              });
            }
          }
        });
      }
    },
    [dataFetched, selectedScotabds, scotabds]
  );

  useEffect(() => {
    const request = async () => {
      const ids = [];
      const promises = [];
      Object.values(selectedAuditAreas).forEach((value) => {
        if (value.length) {
          if (!ids.includes(value[0]))
            promises.push(
              formServices.getSCOTABDs(value[0], true, true).then((response) =>
                response.data
                  .filter((scotabd) => scotabd.MethodologyVersionId === parseInt(methodologyId))
                  ?.map((scotabd) => ({
                    label: scotabd.ClassOfTransactionName,
                    value: scotabd.SCOTABDId,
                    displayOrder: scotabd.DisplayOrder,
                  }))
              )
            );
          ids.push(value[0]);
        }
      });
      const response = await Promise.all(promises);
      const actualData = [];
      response.forEach((data) => {
        data.forEach((scotabd) => {
          actualData.push(scotabd);
        });
      });
      actualData.sort((a, b) => a.displayOrder - b.displayOrder);
      const uniqueData = actualData.filter((item, index, self) => self.findIndex(obj => obj.value === item.value) === index);
      setScotabds(uniqueData);
    };
    request();
  }, [selectedAuditAreas, methodologyId]);

  useEffect(() => {
    if (Array.isArray(schema?.properties)) {
      const sections = findSections(schema.properties);
      const applicableFields = findApplicableLocalFields(schema.properties, additionalData?.id);
      setSections(sections);
      setFields(applicableFields);
    }
  }, [schema?.properties]);

  useEffect(() => {
    setValue('rules.criterias', criterias)
  }, [criterias]);

  return (
    <Grid container spacing={4}>
      {criterias?.map((criteria) => (
        <Grid key={criteria.id} item xs={12}>
          <CriteriaRow
            id={criteria.id}
            methodologyVersionId={schema?.formSettings?.methodologyVersionId}
            formSchemaID={schema?.formSettings?.formSchemaID}
            formName={schema?.formSettings?.formName}
            formIndustries={schema?.formSettings?.formIndustry}
            formCategoryOptions={formCategoryOptions}
            formCategory={schema?.formSettings?.formCategoryId}
            trigger={trigger}
            criteria={criteria}
            criterias={criterias}
            sections={sections}
            fields={fields}
            industries={industriesData}
            selectedIndustries={processedIndustries}
            auditAreas={filteredAuditAreas}
            selectedAuditAreas={selectedAuditAreas}
            scotabds={scotabds}
            selectedScotabds={selectedScotabds}
            frameworks={frameworkData}
            performanceStandards={performanceStandardData}
            setCriterias={setCriterias}
            deleteCriteria={deleteCriteria}
            setSelectedIndustries={setSelectedIndustries}
            setSelectedAuditAreas={setSelectedAuditAreas}
            setSelectedScotabds={setSelectedScotabds}
            ruleCriteriaTypes={ruleCriteriaTypes}
            serviceTypeConfiguration={serviceTypeConfiguration}
          />
        </Grid>
      ))}
      <Grid item>
        <Button
          onClick={addCriteria}
          startIcon={<AddIcon sx={{ fontSize: '12px', color: colors.riptide[500] }} />}
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: colors.charcoal,
          }}
        >
          ADD CRITERIA
        </Button>
      </Grid>
    </Grid>
  );
};
