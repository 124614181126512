import React, { useEffect } from "react"
import {
    createRoutesFromElements,
    createBrowserRouter,
    Outlet,
    Route,
    RouterProvider,
    redirect,
    useLoaderData,
    Await
} from "react-router-dom"
import { QueryClient } from "@tanstack/react-query"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useMsal } from "@azure/msal-react"
import { DndProvider } from "react-dnd"
import loadable from "@loadable/component"

import { AppTypeProvider } from '@ais/providers';
import { AppProviders } from "@providers/AppProviders"
import CLAHeader from "@components/CLAHeader/CLAHeader.jsx"
import RequireFunctionalRight from "@components/RequireFunctionalRight/RequireFunctionalRight"
import RequireFunctionalGroup from "@components/RequireFunctionalGroup/RequireFunctionalGroup"
import RequireFunctionalGroupCLAStandard from "@components/RequireFunctionalGroupCLAStandard/RequireFunctionalGroupCLAStandard"
import { ROUTE_PATH, GROUP_ROLE } from "@constants/navigation"
import RequireProjectState from "@components/RequireProjectState/RequireProjectState"
import usersServices from '@services/users.js'

import { loader } from "@services/loaders/project"

import { CLABackDrop, RouteError } from '@ais/components';
import { PROJECT_STATE } from "../constants/common"
import useUserProviderState from "@contexts/UserContext";

import { ProjectContextProvider } from "@contexts/ProjectContext"
import { ProjectFormInstanceProvider } from "../providers"
import { ProjectManagementProvider as ProjectManagementWithUnitProvider } from "../providers"
import { ProjectManagementProvider } from '@contexts/ProjectManagement/ProjectManagementContext'
import { CopyAnswerModalProvider } from '@contexts/ProjectManagementCopyAnswerModal/copyAnswerModalContext';
import ProjectFormRenderer from "@views/ProjectFormRenderer/ProjectFormRenderer";
import ProjectFormRendererV2 from "@views/ProjectFormRenderer/v2/ProjectFormRendererV2";
import { formScopes } from "@constants/scopes.js";

const Dashboard = loadable(() => import("@views/Dashboard/Dashboard.jsx"))
const CreateProjectClientAndProject = loadable(() => import("@views/CreateNewProject/ClientAndProject/CreateProjectClientAndProject.jsx"))
const CreateProjectForms = loadable(() => import("@views/CreateNewProject/Forms/CreateProjectForms.jsx"))
const CreateProjectReports = loadable(() => import("@views/CreateNewProject/Reports/CreateProjectReports.jsx"))
const CreateProjectUnits = loadable(() => import("@views/CreateNewProject/Units/CreateProjectUnits.jsx"))

const FormsLibrary = loadable(() => import("@views/FormsLibrary.jsx"))

const ServiceType = loadable(() => import("@views/ServiceType/ServiceType.jsx"))
const ProceduresLibraryRoute = loadable(() => import("@views/ProceduresLibrary/ProceduresLibraryRoute.jsx"))
const KPILibrary = loadable(() => import("@views/KPILibrary.jsx"))
const UnauthorizedErrorPage = loadable(() => import("@views/UnauthorizedErrorPage/UnauthorizedErrorPage.jsx"))
const NoAccess = loadable(() => import("@views/NoAccess/NoAccess"))

const Users = loadable(() => import("@views/Users.jsx"))
const ProjectDashboard = loadable(() => import("@views/ProjectDashboard/ProjectDashboard"))
const ProjectManagement = loadable(() => import("@views/ProjectManagement/ProjectManagement"))
const Diagnostics = loadable(() => import("@views/Diagnostics/Diagnostics"))

const KPIs = loadable(() => import("@views/KPIs/KPIs"))
const ListsLibrary = loadable(() => import("@views/ListsLibrary/ListsLibrary"))
const Methodology = loadable(() => import("@views/Methodology/Methodology"))
const ProjectAdministration = loadable(() => import("@views/ProjectAdministration/ProjectAdministration"))
const InherentRiskFactorSummary = loadable(() => import("@views/InherentRiskFactorSummary/InherentRiskFactorSummary"))
const DeficiencySummary = loadable(() => import("@views/DeficiencySummary/DeficiencySummary"))
const RiskSummary = loadable(() => import("@views/RiskSummary/RiskSummary"))
const CLAFormDesigner = loadable(() => import("@components/Forms/CLAFormDesigner/CLAFormDesigner"))
const CLAFormHeader = loadable(() => import("@components/Forms/CLAFormHeader/CLAFormHeader"))
const CLAFormHelp = loadable(() => import("@components/Forms/CLAFormHelp/CLAFormHelp"))
const CLAFormSettings = loadable(() => import("@components/Forms/CLAFormSettings/CLAFormSettings"))

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    }
})

const AuthContainer = ({ children }) => {
    const loaderData = useLoaderData()

    return (
        <div>
            <React.Suspense
                fallback={<CLABackDrop isOpen={true} />}
            >
                <Await
                    resolve={loaderData?.data}
                    errorElement={<UnauthorizedErrorPage />}
                >
                    {children}
                </Await>
            </React.Suspense>
        </div>
    )
}

const listLibraryElement = () => (
    <RequireFunctionalGroupCLAStandard>
        <RequireFunctionalRight rightsKey={ROUTE_PATH.listsLibrary} showUnauthorize={true}>
            <RequireFunctionalGroup requiredGroups={[GROUP_ROLE.ADMIN, GROUP_ROLE.NATG_AUTHOR]} route={true}>
                <CLAHeader pageName="ais" />
                <ListsLibrary />
            </RequireFunctionalGroup>
        </RequireFunctionalRight>
    </RequireFunctionalGroupCLAStandard>
)

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<RouteError />}>
            <Route path="/noaccess" element={<NoAccess />} />
            <Route path="/" element={
                <RequireFunctionalGroupCLAStandard>
                    <CLAHeader pageName="ais" />
                    <ProjectManagementProvider>
                        <Dashboard />
                    </ProjectManagementProvider>
                </RequireFunctionalGroupCLAStandard>
            } />

            <Route
                path="/project-dashboard/:projectId"
                loader={loader(queryClient)}
                errorElement={<UnauthorizedErrorPage />}
                element={
                    <AuthContainer>
                        <RequireFunctionalGroupCLAStandard>
                            <CLAHeader pageName="project" />
                            <ProjectDashboard viewName="project-dashboard" />
                        </RequireFunctionalGroupCLAStandard>
                    </AuthContainer>
                }
            />

            <Route
                path="/project-management/:projectId"
                loader={loader(queryClient)}
                id="project-management"
                errorElement={<UnauthorizedErrorPage />}
                element={
                    <AuthContainer>
                        <RequireFunctionalGroupCLAStandard>
                            <Outlet />
                        </RequireFunctionalGroupCLAStandard>
                    </AuthContainer>
                }
            >
                <Route
                    path="project-form-instance-v1/:projectFormId"
                    errorElement={<UnauthorizedErrorPage fromProjectFormInstancePage />}
                    element={
                        <ProjectContextProvider>
                            <CLAHeader pageName="project" />
                            <DndProvider backend={HTML5Backend}>
                                <ProjectFormRenderer />
                            </DndProvider>
                        </ProjectContextProvider>
                    }
                />

                <Route
                    path="project-form-instance/:projectFormId"
                    errorElement={<UnauthorizedErrorPage fromProjectFormInstancePage />}
                    element={
                        <ProjectContextProvider>
                            <CLAHeader pageName="project" />
                            <DndProvider backend={HTML5Backend}>
                                <ProjectFormRendererV2 />
                            </DndProvider>
                        </ProjectContextProvider>
                    }
                />

                <Route
                    path="inherent-risk-factor-summary"
                    element={
                        <>
                            <CLAHeader pageName="project" />
                            <InherentRiskFactorSummary />
                        </>
                    }
                />

                <Route
                    path="deficiency-summary"
                    element={
                        <>
                            <CLAHeader pageName="project" />
                            <DeficiencySummary />
                        </>
                    }
                />

                <Route
                    path="risk-summary"
                    element={
                        <>
                            <CLAHeader pageName="project" />
                            <RiskSummary />
                        </>
                    }
                />

                <Route
                    index
                    element={
                        <>
                            <CLAHeader pageName="project" />
                            <ProjectManagementProvider>
                                <CopyAnswerModalProvider>
                                    <ProjectManagementWithUnitProvider>
                                        <ProjectManagement />
                                    </ProjectManagementWithUnitProvider>
                                </CopyAnswerModalProvider>
                            </ProjectManagementProvider>
                        </>
                    }
                />
            </Route>

            <Route path="/create-new-project" loader={() => redirect("/create-new-project/client-and-project")} element={<></>} />

            <Route
                path="/create-new-project/:projectId/*"
                loader={loader(queryClient)}
                errorElement={<UnauthorizedErrorPage />}
                element={
                    <AuthContainer>
                        <RequireFunctionalGroupCLAStandard>
                            <CLAHeader pageName="ais" />
                            <Outlet />
                        </RequireFunctionalGroupCLAStandard>
                    </AuthContainer>
                }
            >
                <Route path="forms/*" errorElement={<UnauthorizedErrorPage />}
                    loader={loader(queryClient)}
                    element={
                        <AuthContainer>
                            <RequireFunctionalGroupCLAStandard>
                                <CreateProjectForms />
                            </RequireFunctionalGroupCLAStandard>
                        </AuthContainer>
                    } />
                <Route path="units/*" errorElement={<UnauthorizedErrorPage />}
                    loader={loader(queryClient)}
                    element={
                        <AuthContainer>
                            <RequireFunctionalGroupCLAStandard>
                                <ProjectContextProvider>
                                    <CreateProjectUnits />
                                </ProjectContextProvider>
                            </RequireFunctionalGroupCLAStandard>
                        </AuthContainer>
                    } />
                <Route path="reports/*" errorElement={<UnauthorizedErrorPage />}
                    loader={loader(queryClient)}

                    element={
                        <AuthContainer>
                            <RequireFunctionalGroupCLAStandard>
                                <CreateProjectReports />
                            </RequireFunctionalGroupCLAStandard>
                        </AuthContainer>
                    } />
            </Route>

            <Route
                path="/create-new-project/client-and-project"
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <CLAHeader pageName="ais" />
                        <CreateProjectClientAndProject />
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.serviceType}`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.serviceType} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <ServiceType />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsLibrary}/:methodologyId`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <FormsLibrary />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.proceduresLibrary}/:methodologyId`}
                element={<ProceduresLibraryRoute />}
            />

            <Route
                path={`/${ROUTE_PATH.formsDesigner}/:methodologyId/:formId`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <DndProvider backend={HTML5Backend}>
                                <ProjectFormInstanceProvider>
                                    <CLAFormDesigner />
                                </ProjectFormInstanceProvider>
                            </DndProvider>
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.methodology}`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.methodology} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <Methodology />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsSettings}/:methodologyId/:formId`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <CLAFormSettings />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsSettings}/:methodologyId`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <CLAFormSettings />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsSettings}/:methodologyId/:formId/:duplicate`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <CLAFormSettings />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsSettings}`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <FormsLibrary />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsHelp}/:methodologyId/:formId`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <CLAFormHelp />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path={`/${ROUTE_PATH.formsHelp}`}
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.formsLibrary} showUnauthorize={true}>
                            <CLAFormHeader />
                            <CLAFormHelp />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path="/kpi-library"
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.kpiLibrary} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <KPILibrary />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />

            <Route
                path="/diagnostics/:projectId"
                loader={loader(queryClient)}
                errorElement={<UnauthorizedErrorPage />}
                element={
                    <AuthContainer>
                        <RequireProjectState projectState={PROJECT_STATE.ACTIVE}>
                            <CLAHeader pageName="project" />
                            <Diagnostics />
                        </RequireProjectState>
                    </AuthContainer>
                }
            />

            <Route
                path="/kpis/:projectId"
                loader={loader(queryClient)}
                errorElement={<UnauthorizedErrorPage />}
                element={
                    <AuthContainer>
                        <RequireFunctionalGroupCLAStandard>
                            <RequireProjectState projectState={PROJECT_STATE.ACTIVE}>
                                <CLAHeader pageName="project" />
                                <KPIs />
                            </RequireProjectState>
                        </RequireFunctionalGroupCLAStandard>
                    </AuthContainer>
                }
            />

            <Route
                path="/project-administration"
                element={
                    <RequireFunctionalGroupCLAStandard>
                        <RequireFunctionalRight rightsKey={ROUTE_PATH.projectAdminstration} showUnauthorize={true}>
                            <CLAHeader pageName="ais" />
                            <ProjectAdministration />
                        </RequireFunctionalRight>
                    </RequireFunctionalGroupCLAStandard>
                }
            />
            <Route path="/lists-library">
                <Route
                    path=":methodologyVersionId/:listKey"
                    element={listLibraryElement()} />
                <Route
                    path=":methodologyVersionId"
                    element={listLibraryElement()} />
            </Route>

            <Route
                path="/users"
                element={
                    <>
                        <CLAHeader pageName="ais" />
                        <Users />
                    </>
                }
            />


        </Route>
    )
)

export const AppRoutes = () => {
    const { accounts, instance } = useMsal();
    const { addAISPerson } = useUserProviderState();

    useEffect(() => {
        const getTokenFromMsalInstance = async () => {
            const request = { scopes: formScopes, account: accounts[0] };
            const authResult = await instance.acquireTokenSilent(request);
            accounts[0].idTokenClaims.accessToken = authResult.accessToken;
        }

        const addUser = async (id) => {
            const user = await usersServices.addUserbyId(id);
            addAISPerson(user);
        }

        getTokenFromMsalInstance().then(() => addUser(accounts[0]?.localAccountId));
    }, [])


    return (
        <AppProviders>
            <AppTypeProvider isPortalApp>
                <RouterProvider router={router} />
            </AppTypeProvider>
        </AppProviders>
    )
}