export const JOB = {
  PDF_GENERATION: 1,
  LOAD_DIAGNOSTICS: 2,
  UPDATE_PROJECT_METHODOLOGY_VERSION: 3,
  CREATE_METHODOLOGY_VERSION: 4,
  PROJECT_ROLL_FORWARD: 5,
};

export const JOB_INSTANCE_STATUS = {
  NOT_STARTED: 1,
  STARTED: 2,
  COMPLETED: 3,
  FAILED: 4,
  NOTIFIED: 5,
};
