export const NOTIFICATION_MESSAGES = {
    PDF_GENERATION: {
        NOT_STARTED: "The job instance has not yet started.",
        TRY_AGAIN: "Please try again or contact the service desk for help.",
        DOWNLOAD_PDF: "Download PDF",
        CREATION_FAILED: "creation failed. Please try again.",
        IN_PROGRESS: "is being created. This process may take a few minutes. Please do not close your browser.",
        COMPLETED: 'generation is complete.',
        NOTIFIED: 'generation is completed and notified.'
    },
    UPDATE_PROJECT_METHODOLOGY_VERSION: {
        NOT_STARTED: "The job instance has not yet started.",
        IN_PROGRESS: 'methodology version update is in progress.',
        COMPLETED: 'methodology version update is complete.',
        FAILED: 'methodology version update failed. Please try again.',
        CLICK_LINK: 'Click here'
    },
};

