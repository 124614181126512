export const ensureHttpProtocol = (url) => {
    try {
        if (!url) return url;
        // Try to parse the URL
        const parsedUrl = new URL(url);
        return parsedUrl.href;
    } catch (e) {
        // If parsing fails, check if the input is a valid URL format
        const urlMatch = url.match(/^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/.*)?$/);
        if (urlMatch) {
            return `https://${url}`;
        }
        // If it's not a valid URL, return the original input
        return url;
    }
};

export const extractDomainFromUrl = (url) => {
    // Use a regular expression to match the domain part at the end of the URL
    const match = url?.match(/([^/]+)$/);
    // Check if the match is a valid domain
    const isDomain = match && match[1].match(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    // If it's a valid domain, return it; otherwise, return the original URL
    return isDomain ? match[1] : url;
};

export const extractTextOrUrlFromLink = (url) => {
    url = extractDomainFromUrl(url);
    url = ensureHttpProtocol(url);
    return url;
};

export const extractTextOrUrl = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const elements = doc.body.childNodes;

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        // Check if the node is an Element
        if (element.nodeType === Node.ELEMENT_NODE) {
            const linkElements = element.querySelectorAll('a');

            linkElements.forEach(link => {
                // Use a regular expression to match the href value
                const hrefMatch = link.outerHTML.match(/href="([^"]*)"/);
                // Get the href value if there's a match
                const hrefValue = hrefMatch?.[1] ?? link.href;
                const url = extractTextOrUrlFromLink(hrefValue);
                link.setAttribute('href', url);
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('target', '_blank');
                link.setAttribute('style', 'color: #0d6efd;');
            });
        }
    }
    
    return doc.body.innerHTML;
};
