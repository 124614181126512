export const PROCEDURES_LIBRARY = {
    PROCEDURE_SELECTED_METHODOLOGY: "PROCEDURE_SELECTED_METHODOLOGY",
    NODEIDS_EXTENSION: {
        AUDITAREA: 'AuditArea',
        PROCEDURE: 'Procedure',
        STEP: 'Step',
        SUB_STEP: 'SubStep',
    },
    EN: {
        TITLE: 'Procedures Library',
        FILTER_LABEL: 'Filter',
        ACCORDION: {
            PROCEDURES: {
                TITLE: 'Procedures',
                PANEL: 'procedures-library-panel',
                CHANGE_ORDER_BTN: 'Change Order'
            },
            APPROVALS: {
                TITLE: 'Approvals',
                PANEL: 'procedures-approval-panel'
            },
            REPORT: {
                TITLE: 'Reports',
                PANEL: 'reports-panel'
            }
        },
        TREEVIEW: {
            ROOTID: 'PROCEDURES-LIBRARY-01',
            ALTEXPAND: 'Expand',
            ALTCOLLAPSE: 'Collapse',
            NODATALABEL: 'No procedure data loaded.',
            OPTIONS: {
                ADD_PROCEDURE: 'Add Procedure',
                ADD_STEP: 'Add Step',
                ADD_SUBSTEP: 'Add Sub-Step',
                VIEWEDIT: 'View/Edit',
                DELETE: 'Delete',
                DUPLICATE: 'Duplicate'
            }
        },
        ACTIONS: {
            ADD: 'Add Procedure'
        },
        DELETE_MODAL: {
            DELETE_TITLE: "Warning",
            DELETE_MESSAGE: "This procedure will be permanently deleted. All Steps and Sub-Steps will also be deleted.",
        },
        DELETE_STEP_MODAL: {
            DELETE_STEP_TITLE: "Warning",
            DELETE_STEP_MESSAGE: "Are you sure you want to delete this Step? Sub-Steps will also be deleted.",
        },
        DELETE_SUBSTEP_MODAL: {
            DELETE_SUBSTEP_TITLE: "Warning",
            DELETE_SUBSTEP_MESSAGE: "Are you sure you want to delete this Sub-Step? Child Sub-Steps will also be deleted.",
        },
        DUPLICATE_STEP_MODAL:{
            DUPLICATE_STEP_TITLE: 'Duplicate and rename',
            DUPLICATE_STEP_EXT_TEXT: 'Append text to duplicated item',
            DUPLICATE_STEP_SUBITEMS_TEXT: 'Apply to sub-items'
        },
        DUPLICATE_SUB_STEP_MODAL:{
            DUPLICATE_SUB_STEP_TITLE: 'Duplicate and rename',
            DUPLICATE_SUB_STEP_EXT_TEXT: 'Append text to duplicated item',
            DUPLICATE_SUB_STEP_SUBITEMS_TEXT: 'Apply to sub-items'
        },
        MODAL: {
            TITLE: 'Procedure Details',
            FIELDS: {
                PROCEDURE_NAME: {
                    NAME: 'procedureName',
                    LABEL: 'Procedure Name',
                    PLACEHOLDER: 'Enter procedure name'
                },
                CHECKBOX: {
                    LABEL: 'Required',
                    NAME: 'required'
                },
                AUDITAREA: {
                    NAME: 'auditAreas',
                    LABEL: 'Audit Area',
                    PLACEHOLDER: 'Select'
                },
                SCOTABDS: {
                    NAME: 'scotabds',
                    LABEL: 'SCOTABDs',
                    PLACEHOLDER: 'Select'
                }
            }
        },
        EXPORT_MODAL: {
            TITLE: 'Export Procedures Library',
            EXPORT: 'Export',
            MESSAGE: 'This action can take several minutes. Are you sure you want to continue?',
            CANCEL: 'Cancel'
        },
        DUPLICATE_MODAL: {
            TITLE: 'Duplicate and rename',
            EXPORT: 'Export',
            INPUT_LABEL: 'Append text to duplicated item',
            CHK_LABEL: 'Apply to sub-items',
            CANCEL: 'Cancel'
        },
        EXPORT_PROCEDURES: 'Export Procedures',
        APPROVALS: {
            READY_FOR_RELEASE: 'Ready for Release',
            NO_DATA_LABEL: 'No data loaded. Please select a methodology version to load data',
            GRID_HEADER: {
                METHODOLOGY_VERSION: 'Methodology Version',
                APPROVAL_COMMENTS: 'Approval Comments',
                PREPARER: 'Preparer',
                REVIEWER1: 'Reviewer 1',
                STATUS: 'Status'
            },
            CONFIRMATION_MODAL: {
                APPROVE_MESSAGE: 'Are you sure you want to approve this Procedure Library Ready for Release?',
								UNAPPROVE_MESSAGE: 'Are you sure you want to unapprove this Procedure Library?  It will remove all sign offs and revert the Procedure back to Draft status.'

            },
            CONTEXT_MENU: {
                REMOVE_SIGNOFF: 'Remove Sign-off',
                UNAPPROVE: 'Unapprove'
            }
        },
        IS_REQUIRED_VALUES: {
            TRUE: 1,
            FALSE: 0
        },
        STEP_SUBSTEP_MODAL: {
            TITLE: "Step or Sub-Step Details",
            TABS: ["Settings", "Attributes"],
            SETTINGS: "Settings",
            ATTRIBUTES: "Attributes",
            SETTINGS_VIEW: {
                DESCRIPTION: "Description",
                DESCRIPTION_PLACEHOLDER: "Enter description",
                REQUIRED: "Required",
                ADDITIONAL_GUIDANCE: "Additional Guidance",
                ADDITIONAL_GUIDANCE_PLACEHOLDER: "Enter additional guidance",
                ADD_LINKS: "Add links to external references",
                ADD_LINKS_PLACEHOLDER: "Enter links to external references",
                SIGNOFF_REQUIRED: "sign-off is required"
            },
            MODAL_MODE: {
                ADD_STEP: "ADD STEP",
                ADD_SUBSTEP: "ADD SUBSTEP",
                EDIT_STEP: "EDIT STEP"
            },
            ATTRIBUTES_VIEW: {
                PURPOSE: "Purpose",
                OPTIONAL_PLACEHOLDER: "Select (Optional)",
                REQUIRED_PLACEHOLDER: "Select",
                STEP_NATURE: "Nature of Step or Sub-Step",
                TIMING: "Timing",
                DEPENDENCY: "Dependency",
                INDUSTRY: "Industry",
                PERFORMANCE_STANDARDS: "Performance Standards",
                PRIMARY_ASSERTION: "Primary Assertion",
                SECONDARY_ASSERTION: "Secondary Assertion",
                FRAMEWORK: "Framework"
            },
            TREE_LEVEL: {
                PROCEDURE: "Procedure",
                STEP: "Step"
            }
        },
        MAXIMUM_SUBSTEP_LEVEL: 7
    }
}