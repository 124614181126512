import { useState, useEffect, useContext } from 'react';
import { useQueryClient } from "@tanstack/react-query"
import { useParams } from 'react-router-dom';

import { ScopingFrameworkAndPerformanceStandardContext } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext"
import { useFrameworkAuditAreaData, useFrameworks } from '@services/forms/frameworks';
import projectScopeFrameworkService from '@services/forms/projectScopeFrameworks';
import formServices from '@services/forms/forms';
import { useCustomToast, useCustomToastV2 } from '@hooks/useCustomToast';
import { useProjectScope } from '../CLAProjectScopeAuditArea/hooks/useProjectScope';
import { sortReviewerSignoffAsc, getLatestAddedData } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

export const useCLAInitialScopingFramework = ({
    methodologyVersionId,
    drawerOpen,
    signOffList
}) => {

    const { projectId, projectFormId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const [existingFramework, setExistingFramework] = useState([]);
    const [onOpenSelectedFrameworks, setOnOpenSelectedFrameworks] = useState([])
    const [isFrameworkModalOpen, setIsFrameworkModalOpen] = useState(false)
    const [selectedFramework, setSelectedFramework] = useState([]);
    const [savedFrameworkIds, setSavedFrameworkIds] = useState([])
    const [associatedFrameworkData, setAssociatedFrameworkData] = useState({
        associatedRiskFactors: [],
        associatedRisks: [],
        associatedDeficiency: [],
        associatedKeyControls: []
    })
    const [isFrameworkHighlighted, setIsFrameworkHighlighted] = useState(false)
    const queryClient = useQueryClient();
    const {
        projectScopeId,
        fetchProjectScopeData,
        setFrameworks,
        setConcurrencyEventReceived,
        concurrencyEventReceived,
        frameworksHighlight,
    } = useProjectScope();

    const {
        data: auditAreaFrameworkData,
        isLoading: isLoadingAuditAreaFrameworkData,
        isRefetching: isRefetchingAuditAreaFrameworkData
    } = useFrameworkAuditAreaData(projectScopeId, savedFrameworkIds)
    const { data: frameworks, isLoading: isFrameworksLoading } = useFrameworks(methodologyVersionId, true);
    const { setSelectedFrameworks: _setSelectedFrameworks } = useContext(ScopingFrameworkAndPerformanceStandardContext)

    const [frameworkSaveLoading, setFrameworkSaveLoading] = useState(false)
    const [frameworkSaveError, setFrameworkSaveError] = useState(false)
    const [frameworkSaveSuccess, setFrameworkSaveSuccess] = useState(false)
    useCustomToast({
        isLoading: frameworkSaveLoading,
        isSuccess: frameworkSaveSuccess,
        isError: frameworkSaveError
    })

    useCustomToastV2({
        isLoading: frameworkSaveLoading,
        isSuccess: frameworkSaveSuccess,
        isError: frameworkSaveError
    })

    useEffect(() => {
        fetchProjectScopeData()
    }, [projectFormId, projectId])

    const handleFrameworks = (frameworks) => {
        setSelectedFramework(frameworks)
        _setSelectedFrameworks(frameworks)
    }

    const handleExistingFrameworks = (frameworks) => {
        setExistingFramework(frameworks);
    }

    const saveFrameworks = async () => {
        const inExistingFramework = existingFramework.filter(x => selectedFramework.includes(x.FrameworkId));
        const newFrameworkSelectedIds = selectedFramework.filter(x => !inExistingFramework.map(o => o.FrameworkId).includes(x));

        const newFramework = [
            ...inExistingFramework.map(updateData => ({
                FrameworkId: updateData.FrameworkId,
                ProjectScopeFrameworkId: updateData.ProjectScopeFrameworkId
            })),
            ...newFrameworkSelectedIds.map(newData => ({
                FrameworkId: newData,
                ProjectScopeFrameworkId: null
            }))
        ]

        if (!projectScopeId) return;

        const payloadBody = {
            ProjectScopeId: projectScopeId,
            Data: newFramework,
            RequestProjectFormId: projectFormId
        }

        try {
            setFrameworkSaveLoading(true);
            const response = await projectScopeFrameworkService.updateProjectScopeFramework(projectId, payloadBody);

            if (response.status === 201) {
                setFrameworkSaveLoading(false);
                const loadedFramework = await projectScopeFrameworkService.getProjectScopeFramework(projectScopeId, projectId);
                setExistingFramework(loadedFramework?.response?.data);
                setFrameworkSaveSuccess(true)
            }
        } catch (err) {
            setFrameworkSaveLoading(false);
            setFrameworkSaveError(true)
            throw err
        } finally {
            await fetchProjectScopeData()
            setConcurrencyEventReceived(false)
        }
    }

    const handleFrameworkClose = async () => {
        const existingFrameworkIds = existingFramework.map(({ FrameworkId }) => FrameworkId)
        let removedFromFrameworkProjectScopeIds = []
        if (selectedFramework.sort().join(',') !== existingFrameworkIds.sort().join(',')) {
            removedFromFrameworkProjectScopeIds = existingFramework
                .filter(x => !selectedFramework.includes(x.FrameworkId))
                .map(({ ProjectScopeFrameworkId }) => ProjectScopeFrameworkId)
        } else {
            return;
        }

        if (removedFromFrameworkProjectScopeIds.length > 0) {
            queryClient.invalidateQueries({
                queryKey: ['frameworkAuditAreaData', ...selectedFramework],
            })
            setSavedFrameworkIds(selectedFramework)

        } else {
            await saveFrameworks()
        }
    };

    const onConfirmFrameworkWarningModal = async () => {
        handleFrameworks(onOpenSelectedFrameworks)
        setIsFrameworkModalOpen(false)
        setSavedFrameworkIds([])
    }

    const handleFrameworkOpen = () => {
        setOnOpenSelectedFrameworks(selectedFramework)
    }

    const handleFrameworkChange = async (event) => {
        const frameworks = event.target.value;
        const selectedFrameworks = typeof frameworks === 'string' ? frameworks.split(',') : frameworks;

        if (selectedFrameworks.length === 0 && onOpenSelectedFrameworks.length > 0) {
            const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
            const auditAreaCount = projectScopeInfo?.data?.AuditAreas?.length;
            if (auditAreaCount > 0) {
                return;
            }
        }

        setSelectedFramework(selectedFrameworks);
        _setSelectedFrameworks(selectedFrameworks);
    }

    const loadFrameworks = async () => {
        const loadedFramework = await projectScopeFrameworkService.getProjectScopeFramework(projectScopeId, projectId);

        const _frameworks = loadedFramework?.response?.data || []
        handleFrameworks(_frameworks.map(x => x?.FrameworkId))
        setExistingFramework(_frameworks);
    };

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        if (frameworksHighlight?.length) {
            const dateModified = getLatestAddedData(frameworksHighlight);
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
            setIsFrameworkHighlighted(isSignoffEnabled && shouldHighlight)
        }
    }, [frameworksHighlight, signOffList])

    useEffect(() => {
        if (projectScopeId && !concurrencyEventReceived) {
            loadFrameworks();
        }
    }, [projectScopeId, drawerOpen, concurrencyEventReceived]);

    useEffect(() => {
        if (isLoadingAuditAreaFrameworkData || isRefetchingAuditAreaFrameworkData) return

        const {
            RiskFactors,
            Risks,
            Deficiencies,
            KeyControls,
        } = auditAreaFrameworkData

        if (RiskFactors.length === 0
            && Risks.length === 0
            && Deficiencies.length === 0
            && KeyControls.length === 0
        ) {
            setIsFrameworkModalOpen(false)
            saveFrameworks()
            return;
        }

        setIsFrameworkModalOpen(true)
        setAssociatedFrameworkData({
            associatedRiskFactors: RiskFactors,
            associatedRisks: Risks,
            associatedDeficiency: Deficiencies,
            associatedKeyControls: KeyControls,
        })
    }, [isLoadingAuditAreaFrameworkData, isRefetchingAuditAreaFrameworkData])

    return {
        handleFrameworkClose,
        onConfirmFrameworkWarningModal,
        handleFrameworkOpen,
        handleFrameworkChange,
        isFrameworkModalOpen,
        frameworks,
        isFrameworksLoading,
        selectedFramework,
        hasLoadingAuditAreaFrameworkData: isLoadingAuditAreaFrameworkData,
        auditAreaFrameworkData,
        setIsFrameworkModalOpen,
        associatedFrameworkData,
        saveFrameworks,
        handleFrameworks,
        isFrameworkHighlighted,
        handleExistingFrameworks
    }
}

