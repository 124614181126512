export const LIBRARY_MENU_OPTIONS = {
    FORMS: 1,
    PROCEDURES: 2,
    LISTS: 3
};

export const FORMS_LIBRARY_MENU_ITEMS = [
    {
        id: LIBRARY_MENU_OPTIONS.FORMS,
        label: 'Forms Library ',
        path: '/forms-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.LISTS,
        label: 'Lists Library ',
        path: '/lists-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.PROCEDURES,
        label: 'Procedures Library ',
        path: '/procedures-library/'
    }
];

export const PROCEDURES_LIBRARY_MENU_ITEMS = [
    {
        id: LIBRARY_MENU_OPTIONS.PROCEDURES,
        label: 'Procedures Library ',
        path: '/procedures-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.LISTS,
        label: 'Lists Library ',
        path: '/lists-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.FORMS,
        label: 'Forms Library ',
        path: '/forms-library/'
    }
];

export const LISTS_LIBRARY_MENU_ITEMS = [
    {
        id: LIBRARY_MENU_OPTIONS.LISTS,
        label: 'Lists Library ',
        path: '/lists-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.FORMS,
        label: 'Forms Library ',
        path: '/forms-library/'
    },
    {
        id: LIBRARY_MENU_OPTIONS.PROCEDURES,
        label: 'Procedures Library ',
        path: '/procedures-library/'
    }
];