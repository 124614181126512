import clientApi from '@utilities/claApiClient';

export const downloadProjectPdfBlob = async (projectId, fileName) => {
    const targetURL = `/projects/pdf/${projectId}.pdf`;

    let response = await clientApi.get(targetURL, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
    // Change to open PDF file contents in new tab
    let link = URL.createObjectURL(new Blob([response?.data]), { type: 'application/pdf' });
    triggerFileDownload(link, fileName);
}

export const markNotificationAsNotified = async (jobInstanceId) => {
    try {
        const targetURL = `/projects/pdf/notified`;

        let response = await clientApi.put(targetURL, { jobInstanceId }, {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return { status: 200, data: response?.data }
    }
    catch (exception) {
        return { status: 400, message: exception }
    }
}

const triggerFileDownload = (link, fileName) => {
    // A bit of a hacky way to trigger the download of the file at our blob URL
    const virtualLink = document.createElement('a')
    virtualLink.href = link;
    virtualLink.download = fileName;
    document.body.appendChild(virtualLink);
    virtualLink.click();
    document.body.removeChild(virtualLink);
}