import formApi from '@utilities/claApiForm';
import { useQuery } from '@tanstack/react-query';
import logger from '@utilities/logService';

/** Used for Risk Factors only. For Risks, find services/scotabd.js */
export const fetchScotabdList = async (
  ProjectId,
  ProjectScopeIds,
  ProjectScopeAuditAreaId,
  IsFromRiskFactors = true
) => {
  try {
    if (ProjectScopeAuditAreaId.length === 0) return;
    const body = {
      ProjectScopeIds,
      ProjectScopeAuditAreaId,
      IsFromRiskFactors,
    };
    const { data } = await formApi.post(
      `/projectscopesauditareascotabd/${ProjectId}`,
      body
    );
    return data;
  } catch (error) {
    logger.error(error);
  }
};

// EXPORTS ====================================================================
/** @deprecated Unused */
export const useGetScotabdList = (
  ProjectId,
  ProjectScopeIds,
  ProjectScopeAuditAreaId,
  index
) => {
  return useQuery(
    ['ScotabdList', ProjectScopeIds, ProjectScopeAuditAreaId, index],
    () => fetchScotabdList(ProjectId, ProjectScopeIds, ProjectScopeAuditAreaId),
    {
      enabled:
        !!ProjectScopeIds &&
        Array.isArray(ProjectScopeIds) &&
        ProjectScopeIds.length > 0 &&
        !!ProjectScopeAuditAreaId &&
        Array.isArray(ProjectScopeAuditAreaId) &&
        ProjectScopeAuditAreaId.length > 0,
    }
  );
};
