import { useState, useEffect, useContext } from 'react';
import { useQueryClient } from "@tanstack/react-query"
import { useParams } from 'react-router-dom';

import formServices from '@services/forms/forms';
import performanceStandardsService, { usePerformanceStandardsAuditAreaData } from '@services/forms/performanceStandards';
import projectScopePerformanceStandardsService from '@services/forms/projectScopePerformanceStandards';
import { ScopingFrameworkAndPerformanceStandardContext } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext"
import { useCustomToast, useCustomToastV2 } from '@hooks/useCustomToast';
import { useProjectScope } from '../CLAProjectScopeAuditArea/hooks/useProjectScope';
import { sortReviewerSignoffAsc, getLatestAddedData } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

export const useCLAInitialScopingPerformanceStandards = ({
    methodologyVersionId,
    drawerOpen,
    signOffList
}) => {

    const { projectId, projectFormId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const [performanceStandards, setPerformanceStandards] = useState([]);
    const [onOpenSelectedPerformanceStandards, setOnOpenSelectedPerformanceStandards] = useState([])
    const [existingperformanceStandards, setExistingperformanceStandards] = useState([]);
    const [selectedPerformanceStandard, setSelectedPerformanceStandard] = useState([]);
    const [savedPerformanceStandardIds, setSavedPerformanceStandardIds] = useState([]);
    const [isPerformanceStandardModalOpen, setIsPerformanceStandardModalOpen] = useState(false)
    const [associatedStandardPerformancData, setAssociatedStandardPerformanceData] = useState({
        associatedRiskFactors: [],
        associatedRisks: [],
        associatedDeficiency: [],
        associatedKeyControls: []
    });
    const [performanceStandardSaveLoading, setPerformanceStandardSaveLoading] = useState(false)
    const [performanceStandardSaveError, setPerformanceStandardSaveError] = useState(false)
    const [performanceStandardSaveSuccess, setPerformanceStandardSaveSuccess] = useState(false)
    const [isPerformanceStandardsHighlighted, setIsPerformanceStandardsHighlighted] = useState(false)

    const { setSelectedPerformanceStandards } = useContext(ScopingFrameworkAndPerformanceStandardContext)

    useCustomToast({
        isLoading: performanceStandardSaveLoading,
        isSuccess: performanceStandardSaveSuccess,
        isError: performanceStandardSaveError
    });

    useCustomToastV2({
        isLoading: performanceStandardSaveLoading,
        isSuccess: performanceStandardSaveSuccess,
        isError: performanceStandardSaveError
    })

    useEffect(() => {
        fetchProjectScopeData()
    }, [projectFormId, projectId])

    const queryClient = useQueryClient();
    const {
        projectScopeId,
        fetchProjectScopeData,
        performanceStandardsHighlight: performanceStandardData,
        setPerformanceStandards: setPerformanceStandard,
        setConcurrencyEventReceived,
        concurrencyEventReceived
    } = useProjectScope()

    const {
        data: performanceStandardsAuditAreaData,
        isLoading: isLoadingAuditAreaPerformanceStandardsData,
        isRefetching: isRefetchingAuditAreaPerformanceStandardsData
    } = usePerformanceStandardsAuditAreaData(projectScopeId, savedPerformanceStandardIds)

    const initializePerformanceStandards = async (isEnabledOnly = false) => {
        const { data: standards } = await performanceStandardsService.getPerformanceStandards(methodologyVersionId, isEnabledOnly);
        setPerformanceStandards(standards);
    };

    const loadStandards = async () => {
        const loadedStandard = await projectScopePerformanceStandardsService.getProjectScopePerformanceStandard(projectScopeId, projectId);

        const _performanceStandards = loadedStandard?.response?.data || []
        const performanceStandardIds = _performanceStandards.map(x => x?.PerformanceStandardId)

        setSelectedPerformanceStandard(performanceStandardIds);
        setExistingperformanceStandards(_performanceStandards);
        setSelectedPerformanceStandards(performanceStandardIds)
    };

    const handlePerformanceStandards = (performanceStandardIds) => {
        setSelectedPerformanceStandard(performanceStandardIds);
        setSelectedPerformanceStandards(performanceStandardIds)
    }

    const handleExistingPerformanceStandards = (performanceStandards) => {
        setExistingperformanceStandards(performanceStandards);
    }

    const savePerformanceStandards = async () => {
        if (!projectScopeId) return;

        const inExistingPerformance = existingperformanceStandards.filter(x => selectedPerformanceStandard.includes(x.PerformanceStandardId));
        const removeExistingperformance = selectedPerformanceStandard.filter(x => !inExistingPerformance.map(o => o.PerformanceStandardId).includes(x));

        const newperformance = [
            ...inExistingPerformance.map(updateData => ({
                PerformanceStandardId: updateData.PerformanceStandardId,
                ProjectScopePerformanceStandardId: updateData.ProjectScopePerformanceStandardId
            })),
            ...removeExistingperformance.map(newData => ({
                PerformanceStandardId: newData,
                ProjectScopePerformanceStandardId: null
            }))
        ];

        const payloadBody = {
            ProjectScopeId: projectScopeId,
            Data: newperformance,
            RequestProjectFormId: projectFormId
        };

        try {
            setPerformanceStandardSaveLoading(true)
            const response = await projectScopePerformanceStandardsService.updateProjectscopesperformancestandard(payloadBody, projectId);

            if (response.status === 201) {
                setPerformanceStandardSaveLoading(false)
                const loadedStandard = await projectScopePerformanceStandardsService.getProjectScopePerformanceStandard(projectScopeId, projectId);
                setExistingperformanceStandards(loadedStandard?.response?.data);
                setPerformanceStandardSaveSuccess(true)
            }
        } catch (error) {
            setPerformanceStandardSaveLoading(false)
            setPerformanceStandardSaveError(true)
            throw error
        } finally {
            await fetchProjectScopeData()
            setConcurrencyEventReceived(false)
        }
    }

    const handlePerformanceChange = async (event) => {
        const performanceStandards = event.target.value;
        const selectedPerformanceStandards = typeof performanceStandards === 'string' ? performanceStandards.split(',') : performanceStandards;

        if (selectedPerformanceStandards.length === 0 && onOpenSelectedPerformanceStandards.length > 0) {
            const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
            const auditAreaCount = projectScopeInfo?.data?.AuditAreas?.length;
            if (auditAreaCount > 0) {
                return;
            }
        }

        setSelectedPerformanceStandard(selectedPerformanceStandards);
        setSelectedPerformanceStandards(selectedPerformanceStandards)
    };

    const handlePerformanceStandardOnClose = async () => {
        const existingPerformanceStandardsIds = existingperformanceStandards.map(({ PerformanceStandardId }) => PerformanceStandardId)
        let removedFromPerformanceStandardsProjectScopeIds = []
        const isSelectedItemsEqualToExistingItems = selectedPerformanceStandard.sort().join(',') === existingPerformanceStandardsIds.sort().join(',')
        if (isSelectedItemsEqualToExistingItems) {
            return;
        }

        removedFromPerformanceStandardsProjectScopeIds = existingperformanceStandards
            .filter(x => !selectedPerformanceStandard.includes(x.PerformanceStandardId))
            .map(({ ProjectScopePerformanceStandardId }) => ProjectScopePerformanceStandardId)

        if (removedFromPerformanceStandardsProjectScopeIds.length > 0) {
            queryClient.invalidateQueries({
                queryKey: ['performanceStandardsAuditAreaData', ...selectedPerformanceStandard]
            });
    
            setSavedPerformanceStandardIds(selectedPerformanceStandard);
        } else {
            await savePerformanceStandards()
        }
    };

    const handlePerformanceStandardOpen = async () => {
        setOnOpenSelectedPerformanceStandards(selectedPerformanceStandard);
    }

    const onConfirmPerformanceStandardModal = () => {
        setSelectedPerformanceStandard(onOpenSelectedPerformanceStandards)
        setSelectedPerformanceStandards(onOpenSelectedPerformanceStandards)
        setSavedPerformanceStandardIds([])
        setIsPerformanceStandardModalOpen(false)
    }

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        if (performanceStandardData?.length) {
            const dateModified = getLatestAddedData(performanceStandardData);
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
            setIsPerformanceStandardsHighlighted(isSignoffEnabled && shouldHighlight)
        }
    }, [performanceStandardData, signOffList])

    useEffect(() => {
        if (methodologyVersionId) {
            initializePerformanceStandards(true);
        }
    }, [methodologyVersionId]);

    useEffect(() => {
        if (projectScopeId && !concurrencyEventReceived) {
            loadStandards();
        }
    }, [projectScopeId, drawerOpen, concurrencyEventReceived]);

    useEffect(() => {
        if (isLoadingAuditAreaPerformanceStandardsData || isRefetchingAuditAreaPerformanceStandardsData) return

        const {
            RiskFactors,
            Risks,
            Deficiencies,
            KeyControls,
        } = performanceStandardsAuditAreaData

        if (RiskFactors.length === 0
            && Risks.length === 0
            && Deficiencies.length === 0
            && KeyControls.length === 0
        ) {
            setIsPerformanceStandardModalOpen(false);
            savePerformanceStandards();
            return;
        }

        setIsPerformanceStandardModalOpen(true)
        setAssociatedStandardPerformanceData({
            associatedRiskFactors: RiskFactors,
            associatedRisks: Risks,
            associatedDeficiency: Deficiencies,
            associatedKeyControls: KeyControls
        })
    }, [isLoadingAuditAreaPerformanceStandardsData, isRefetchingAuditAreaPerformanceStandardsData])

    return {
        handlePerformanceChange,
        handlePerformanceStandardOnClose,
        handlePerformanceStandardOpen,
        onConfirmPerformanceStandardModal,
        associatedStandardPerformancData,
        isPerformanceStandardModalOpen,
        performanceStandards,
        selectedPerformanceStandard,
        hasLoadingAuditAreaperformanceStandardsData: isLoadingAuditAreaPerformanceStandardsData,
        setIsPerformanceStandardModalOpen,
        savePerformanceStandards,
        handlePerformanceStandards,
        handleExistingPerformanceStandards,
        isPerformanceStandardsHighlighted
    }
}