import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { RESERVED_SECTION_IDS } from '@ais/constants';

import { ProjectScopeContext } from "@contexts/ProjectScope/ProjectScopeContext"
import formServices from '@services/forms/forms';
import projectScopeAuditAreaCategory from '@services/forms/projectscopeauditareacategory';

export const useProjectScope = () => {
  const { SCOPING: {
    INITIAL_SCOPING_CONSIDERATIONS,
    DETERMINING_SCOTABDS,
  }} = RESERVED_SECTION_IDS;

  const { projectFormId, projectId } = useParams();
  const {
    answerList,
    projectScopeId,
    auditAreas,
    scotabds,
    scotabdsHistory,
    financialStatements, 
    frameworks,
    frameworksHighlight,
    performanceStandardsHighlight,
    performanceStandards,
    deletedAuditAreas,
    deletedFinancialStatements,
    commonRisks,
    isDeterminingScotabdsExpanded,
    isInitialScopingConsiderationExpanded,
    setProjectScopeId,
    setAuditAreas,
    setScotabds,
    setScotabdsHistory,
    setFinancialStatements,
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    setFrameworks,
    setPerformanceStandards,
    setAnswerList,
    setCommonRisks,
    setDeletedAuditAreas,
    setDeletedFinancialStatements,
    setFrameworksHighlight,
    setPerformanceStandardsHighlight,
    setIsDeterminingScotabdsExpanded,
    setIsInitialScopingConsiderationExpanded
  } = useContext(ProjectScopeContext)

  const fetchProjectScopeAuditAreaData = async (projectScopeAuditAreaId) => {
    const response = await projectScopeAuditAreaCategory.getProjectScopeAuditAreaCategory(
      projectScopeAuditAreaId,
      projectId
    );
    if (response.status === 200) {
      const financialStatement = response?.data ?? [];

      setFinancialStatements((prev) => {
        const index = prev.findIndex(
          (auditArea) => auditArea.ProjectScopeAuditAreaId === projectScopeAuditAreaId
        );
        const financialStatementLines = financialStatement.map((data) => ({
          balance: data.Balance,
          financialStatementCategoryName: data.FinancialStatementCategoryName,
          isUserEnteredCategory: data.IsUserEnteredCategory,
          projectScopeAuditAreaCategoryId: data.ProjectScopeAuditAreaCategoryId,
          transactionCount: data.TransactionCount,
          ValidFrom: data.ValidFrom
        }));
        if (index >= 0) prev[index].FinancialStatementLineItems = financialStatementLines;
        else
          prev = [
            ...prev,
            {
              ProjectScopeAuditAreaId: projectScopeAuditAreaId,
              FinancialStatementLineItems: financialStatementLines,
            },
          ];
        return [...prev];
      });
    }
  };

  const fetchProjectScopeData = async () => {
    const response = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
    if (response.status === 400) return false;
    else {
      setFinancialStatements([])
      await response.data.AuditAreas.map(
        async (auditArea) => await fetchProjectScopeAuditAreaData(auditArea.ProjectScopeAuditAreaId)
      );
      setProjectScopeId(response.data.ProjectScopeId);
      setAuditAreas(response.data.AuditAreas);
      setScotabds(response.data.SCOTABDs);
      setScotabdsHistory(response.data.SCOTABDsHistory);
      setDeletedAuditAreas(response.data.deletedAuditAreas);
      setDeletedFinancialStatements(response.data.deletedProjectScopeAuditAreaCategory);
      setPerformanceStandardsHighlight(response.data.performanceStandards);
      setFrameworksHighlight(response.data.frameworks);
      return true;
    }
  };

  const updateAuditAreaData = (projectScopeAuditAreaId, answer, action) => {
    if ('scotabds' in answer) {
      const updatedScotabds = answer.scotabds;
      const unchangedScotabds = scotabds.filter(data => data.ProjectScopeAuditAreaId !== parseInt(projectScopeAuditAreaId)); 
      setScotabds([
        ...unchangedScotabds,
        ...updatedScotabds
      ])
    }

    if ('financialStatement' in answer) {  
      const updatedFinancialStatement = {
        FinancialStatementLineItems: answer.financialStatement.map(data=>{
          return {
            balance: data.Balance,
            financialStatementCategoryName: data.FinancialStatementCategoryName,
            isUserEnteredCategory: data.IsUserEnteredCategory,
            projectScopeAuditAreaCategoryId: data.ProjectScopeAuditAreaCategoryId,
            transactionCount: data.TransactionCount,
          }
        }),
        ProjectScopeAuditAreaId: parseInt(projectScopeAuditAreaId)
      }
      const unchangedFS = financialStatements.filter(data => parseInt(data.ProjectScopeAuditAreaId) !== parseInt(projectScopeAuditAreaId));   
      setFinancialStatements([ 
        updatedFinancialStatement,
        ...unchangedFS
      ])
    }

    if ('auditAreas' in answer) {
      setAuditAreas(answer.auditAreas)
    }

    if ('frameworks' in answer) {  
      setFrameworks(answer.frameworks)  
    }

    if ('performanceStandards' in answer) {  
      setPerformanceStandards(answer.performanceStandards)  
    }

    if('commonRisks' in answer) {
      setCommonRisks(answer.commonRisks)
    }
  }

  const updateNonCustomFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  }

  const updateScopingSectionExpanded = (sectionId, isExpanded) => {
    switch(sectionId) {
      case INITIAL_SCOPING_CONSIDERATIONS:
        setIsInitialScopingConsiderationExpanded(isExpanded);
        return;
      case DETERMINING_SCOTABDS:
        setIsDeterminingScotabdsExpanded(isExpanded);
        return;
      default:
        return;
    }
  };

  return {
    answerList,
    projectScopeId,
    auditAreas,
    scotabds,
    scotabdsHistory,
    financialStatements,
    deletedAuditAreas,
    deletedFinancialStatements,
    frameworks,
    frameworksHighlight,
    performanceStandardsHighlight,
    performanceStandards,
    commonRisks,
    setFrameworks,
    setPerformanceStandards,
    setProjectScopeId,
    setAuditAreas,
    setScotabds,
    setFinancialStatements,
    setCommonRisks,
    fetchProjectScopeAuditAreaData,
    fetchProjectScopeData,
    updateAuditAreaData,
    updateNonCustomFieldAnswer,
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    setAnswerList,
    isDeterminingScotabdsExpanded,
    isInitialScopingConsiderationExpanded,
    updateScopingSectionExpanded
  }
}