import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Collapse,
    IconButton,
    Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { colors } from '@ais/theme';


import CLAOtherRelevantAssertions from './CLAOtherRelevantAssertions';
import CLARisks from './CLARisks';
import { useAccountAssertionLevelRiskState } from './AccountAssertionLevelRiskContext';
import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import formServices from '@services/forms/forms';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import styles from './CLAAccountAssertionLevelRisk.module.css';
import { useParams } from 'react-router-dom'; 

const boxRowStyles = { display: 'flex', flexDirection: 'row' };
const { ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
const CLASCOTABDsCollapsible = (props) => {
    const {
        scotabd,
        index,
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        financialRisks,
        disabled,
        isExpanded: isParentExpanded,
        userId
    } = props;

    const [proceduresAddressRisk, setProceduresAddressRisk] = useState(null);
    const [projectRisksAuditArea, setProjectRisksAuditArea] = useState(null);
    const [projectAuditAreaOtherRelevant, setProjectAuditAreaOtherRelevant] = useState(null);
    const { projectId, projectFormId } = useParams(); 

    const { data: riskList } = useQuery(['ProjectScopeAuditAreaSCOTABDRiskList', scotabd?.projectScopeAuditAreaSCOTABDId], () => {
        if (!scotabd?.projectScopeAuditAreaSCOTABDId) return Promise.reject(false);
        return formServices.getProjectScopeAuditAreaSCOTABDRiskList(scotabd?.projectScopeAuditAreaSCOTABDId, projectId, projectFormId);
    }, { refetchOnWindowFocus: false });  
    const { data: otherRelevant } = useQuery(['ProjectScopeAuditAreaSCOTABDOtherRelevant', scotabd?.projectScopeAuditAreaSCOTABDId, projectFormId], () => {
        if (!scotabd?.projectScopeAuditAreaSCOTABDId) return Promise.reject(false);
        return formServices.getProjectScopeAuditAreaSCOTABDOtherRelevantAssertions(scotabd?.projectScopeAuditAreaSCOTABDId, projectId, projectFormId);
    }, { refetchOnWindowFocus: false });
    const { updateChildExpanded, isChildExpanded  } = useAccountAssertionLevelRiskState();
    const expandCustomId = `CLASCOTABDsCollapsible-${scotabd?.projectScopeAuditAreaSCOTABDId}`
	const isExpanded = isChildExpanded(expandCustomId);

    const getUniqueSummaryProcedures = () => {
        const uniqueSummaryProcedureRisks = [];
        const uniqueCustomProcedures = []
        riskList.riskInfo?.forEach((_risk) => {
            _risk.procedures?.forEach((_procedure) => {
                const isExists = uniqueSummaryProcedureRisks.find(x => x.summaryProcedureId === _procedure.summaryProcedureId);
                if(!isExists) {
                    uniqueSummaryProcedureRisks.push(_procedure);
                }
            })
            _risk.customProcedures.forEach((_procedure) => {
                const isExists = uniqueCustomProcedures.find(x => x.projectFormCustomProcedureId === _procedure.projectFormCustomProcedureId);
                if(!isExists) {
                    uniqueCustomProcedures.push(_procedure);
                }
            })
        })

        uniqueSummaryProcedureRisks.sort((a, b) => a.summaryProcedureId - b.summaryProcedureId);
        uniqueCustomProcedures.sort((a, b) => a.projectRiskProjectFormCustomProcedureId - b.projectRiskProjectFormCustomProcedureId);

        const procedures = uniqueSummaryProcedureRisks?.map(procedure => procedure.summaryProcedureName)
        const customProcedures = uniqueCustomProcedures?.map(procedure => procedure.customProcedureName)

        const allProcedures = procedures.concat(customProcedures)

        setProceduresAddressRisk(allProcedures.join(', '));
    };
    useEffect(()=>{
        updateChildExpanded(expandCustomId, false);
    },[])
    useEffect(() => {
        if (!riskList) return;
        setProjectRisksAuditArea(riskList);
        getUniqueSummaryProcedures();
    }, [riskList]);

    useEffect(() => {
        if (!otherRelevant) return;
        setProjectAuditAreaOtherRelevant(otherRelevant);
    }, [otherRelevant]);
    

    return (
        <>
            <Box sx={{ ...boxRowStyles }}>
                <div className={`${styles['collapse-icon-wrapper']} ${styles['scotabd-row']}`}>
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={() => { updateChildExpanded(expandCustomId, !isExpanded)}}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div className={`${styles.column} ${styles['scotabd-row']} ${styles['first-scotbd-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{
                        ...boxRowStyles,
                        width: '100%'
                    }}>
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{
                                fontSize: '16px',
                                color: colors.gray[500],
                                fontWeight: '600',
                            }}>
                                {scotabd?.classOfTransactionName}
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <div className={`${styles.column}  ${styles['second-scotbd-column']}  ${index > 0 && styles['row-border']} ${styles['scotabd-row']}`}>
                    <Box sx={{
                        flexShrink: 0,
                        width: '100%',
                        margin: '0px 25px'
                    }}>
                        <Typography sx={{
                            fontSize: '16px',
                            color: colors.gray[500],
                            fontWeight: '600',
                        }}>
                            {scotabd?.accountBalanceType && `(${scotabd?.accountBalanceType.map(balance => balance.accountBalanceName).join(', ')})`}
                        </Typography>
                    </Box>
                </div>
            </Box>
            <Collapse in={isExpanded} collapsedSize={0} data-test="collapse-component">
                {projectRisksAuditArea && <CLARisks
                    inherentRiskOptions={inherentRiskOptions}
                    controlRiskOptions={controlRiskOptions}
                    isInternalControlEffectiveness={isInternalControlEffectiveness}
                    projectRisksAuditArea={projectRisksAuditArea}
                    financialRisks={financialRisks}
                    scotabd={scotabd}
                    disabled={disabled}
                    isExpanded={isExpanded && isParentExpanded}
                    userId={userId}
                />}
                {projectAuditAreaOtherRelevant && <CLAOtherRelevantAssertions
                    inherentRiskOptions={inherentRiskOptions}
                    controlRiskOptions={controlRiskOptions}
                    isInternalControlEffectiveness={isInternalControlEffectiveness}
                    projectAuditAreaOtherRelevant={projectAuditAreaOtherRelevant}
                    scotabd={scotabd}
                    disabled={disabled}
                    isExpanded={isExpanded && isParentExpanded}
                    userId={userId}
                />}
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', margin: '0px 30px 30px'}}>
               
                        <Typography sx={{
                           fontWeight: 700, fontSize: '14px',
                            color: colors.gray[500]
                        }}> {CONSTANT_TEXT.PROCEDURES_ADDRESS_RISK}
                            <span style={{ margin: '0px 10px',  fontWeight: 700, fontSize: '14px'}}>{proceduresAddressRisk}</span>
                           
                        </Typography>
                </div>
            </Collapse>
        </>
    );
};

CLASCOTABDsCollapsible.propTypes = {
    scotabd: PropTypes.object,
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    index: PropTypes.number,
    isInternalControlEffectiveness: PropTypes.bool,
    financialRisks: PropTypes.array,
    disabled: PropTypes.bool,
    isExpanded: PropTypes.bool,
}

export default CLASCOTABDsCollapsible;